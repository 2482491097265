import React from "react";
import {Link} from "@material-ui/core";

export default function NewPageLink(props) {

    return (
        <Link style={{cursor: 'pointer'}} href={props.href} target="_blank" rel="noopener noreferrer" onClick={props.onClick}>
            {props.children}
        </Link>
    )
}