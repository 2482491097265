import React from "react";
import {Document, Page} from 'react-pdf'
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export default function Cv() {

    return (
        <Document file="/cv.pdf">
            <Page scale={2} renderAnnotationLayer={false} renderTextLayer={false} pageNumber={1}/>
        </Document>
    )
}
