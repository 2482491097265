import React from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import StickyFooter from "./StickyFooter";
import Cv from "./Cv";

function App() {

    return (
        <>
            <Container maxWidth="lg" style={{paddingBottom: "30px"}}>
                <Cv/>
            </Container>
            <StickyFooter/>
        </>
    );
}

export default App;
