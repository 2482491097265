import React from "react";
import NewPageLink from "./NewPageLink";

export default function GithubBadge() {

    return (
        <NewPageLink href="https://github.com/j1m-renwick">
            <img src="github-badge.png" alt="Github"/>
        </NewPageLink>
    );
}