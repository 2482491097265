import React from "react";
import NewPageLink from "./NewPageLink";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    badge: {
        border: "black 2px solid",
        width: "130px",
    },
}))

export default function CvBadge() {

    const classes = useStyles();

    return (
            <NewPageLink href="cv.pdf">
                <img className={classes.badge} src="cv-badge.jpg" alt="CV"/>
            </NewPageLink>
    );
}
