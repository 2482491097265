import React from "react";
import Container from "@material-ui/core/Container";
import LinkedInBadge from "./LinkedInBadge";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GithubBadge from "./GithubBadge";
import CvBadge from "./CvBadge";

const useStyles = makeStyles((theme) => ({
    footerStyle: {
        backgroundColor: "#b1c6d5", // d5e6f2
        borderTop: "1px solid #E7E7E7",
        position: "fixed",
        left: "0",
        bottom: "0",
        width: "100%",
        zIndex: 1
    },
    containerStyle: {
        marginBottom: theme.spacing(2)
    },
    phantomStyle: {
        display: "block",
        paddingTop: "20px",
        paddingBottom: "20px",
        height: "100px",
        width: "100%"
    },
    badgeContainer: {
        margin: theme.spacing(2, 0, 0),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }
}))

export default function StickyFooter() {

    const classes = useStyles();

    return (
        <div>
            <div className={classes.phantomStyle}/>
            <div className={classes.footerStyle}>
                <Container className={classes.containerStyle} align="center">
                    <div className={classes.badgeContainer}>
                        <CvBadge/>
                        <div>
                            <LinkedInBadge/>
                            <GithubBadge/>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}
