import React from "react";
import NewPageLink from "./NewPageLink";

export default function LinkedInBadge() {

    return (
            <NewPageLink href="https://www.linkedin.com/in/peter-jim-renwick-108127b8">
                <img src="linkedin-badge.png" alt="LinkedIn"/>
            </NewPageLink>
    );
}