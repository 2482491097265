import {ThemeProvider} from "@material-ui/styles";
import React from "react";
import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        h1: {
            fontWeight: "bold"
        },
        h4: {
            fontWeight: "bold"
        }
    }
});

export default function Theme(props) {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}